import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityTitleHeader, SiteHeaderProvider } from '@shapeable/web';
import { SliceLayoutBoundary } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-partners');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  .shp--entity-title-header__title {
    font-weight: 300;
    font-size: 3rem;
  }
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutPartners: PageLayoutComponent = 
(props) => {
  const { className, children, entity, slices } = props;

  return (
    <SiteHeaderProvider value={{ variant: 'overlay' }}>
      <My.Container className={className}>
        <EntityTitleHeader showOverlayGradient overlayColor='#63898580' backgroundImage={entity.banner} entity={entity} />
        <SliceLayoutBoundary>
          {slices}
          {children}
        </SliceLayoutBoundary>
      </My.Container>
    </SiteHeaderProvider>
    )
};