import React from 'react';
import { IconComponent } from '@shapeable/types'

export const PolicyIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="61" viewBox="0 0 62 61" fill="none">
    <g clip-path="url(#clip0_8423_20477)">
      <path d="M31 5.55189C44.7208 5.55189 55.8791 16.6068 55.8791 30.1895C55.8791 43.7722 44.7158 54.8271 31 54.8271C17.2841 54.8271 6.12082 43.7771 6.12082 30.1895C6.12082 16.6019 17.2792 5.55189 31 5.55189ZM31 0.364258C14.3661 0.364258 0.882324 13.7172 0.882324 30.1895C0.882324 46.6618 14.3661 60.0147 31 60.0147C47.6338 60.0147 61.1176 46.6618 61.1176 30.1895C61.1176 13.7172 47.6338 0.364258 31 0.364258Z" fill="black"/>
      <path d="M31.0001 17.4092C23.9427 17.4092 18.1641 23.1249 18.1641 30.1206C18.1641 37.1163 23.9358 42.832 31.0001 42.832C38.0644 42.832 43.8361 37.1163 43.8361 30.1206C43.8361 23.1249 38.0644 17.4092 31.0001 17.4092ZM28.2214 37.1095L21.8068 30.7572L23.9427 28.6421L28.2214 32.8792L36.7787 24.4049L38.9146 26.5201L28.2214 37.1095Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_8423_20477">
        <rect width="60.2353" height="59.6505" fill="white" transform="translate(0.882324 0.364258)"/>
      </clipPath>
    </defs>
  </svg>
);