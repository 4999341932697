import React from 'react';
import { LinearNavigationProvider as CoreProvider, LinearNavigationItem, useEntity, useTopics, useTrends, useActiveEntity } from "@shapeable/ui"
import { Trend } from '@shapeable/adsw-types';
import { compact, sortBy } from 'lodash';
import { PAGE_NAVIGATOR, PAGE_GPT } from '../../data';
import { entityOutlineNumberRank } from '@shapeable/utils';

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {

  const trends = useTrends() as Trend[];
  const items: LinearNavigationItem[] = [{
    ...PAGE_NAVIGATOR,
    depth: 0,
    type: null,
  }];

  for (const trend of sortBy<Trend>(trends, entityOutlineNumberRank)) {
    items.push({
      id: trend.id,
      name: compact([trend.outlineNumber, trend.name]).join(" - "),
      depth: 1,
      path: trend.path,
      color: trend.color,
      slug: trend.slug,
    });

    for (const topic of sortBy(trend.topics, entityOutlineNumberRank)) {
      items.push({
        id: topic.id,
        name: compact([topic.outlineNumber, topic.name]).join(" - "),
        depth: 2,
        path: topic.path,
        slug: topic.slug,
        _schema: topic._schema,
      });
    }
  }

  return (
    <CoreProvider value={items}>
    {children}
    </CoreProvider>
  )
}