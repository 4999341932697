import React from 'react';
import { LinearNavigationProvider } from './linear-navigation-provider';
import { ActiveEntityProvider, useAuth } from '@shapeable/ui';
import { withAuthenticationRequired, WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { Page } from '@shapeable/types';

export const WITH_AUTHENTICATION_REQUIRED_OPTIONS: WithAuthenticationRequiredOptions = {
  loginOptions: {
    appState: { targetUrl: '/navigator' },
  }
};

export const PAGE_NAVIGATOR: Page = {
  id: 'explorer',
  slug: 'explorer',
  path: '/explorer',
};

const NavigatorProviderComponent: React.FC<any> = ({ children }) => {
  const auth = useAuth();
  return (
    <ActiveEntityProvider value={PAGE_NAVIGATOR}>
    <LinearNavigationProvider>
    {children}
    </LinearNavigationProvider>
    </ActiveEntityProvider>
  );
};

let authRequired = true;

if (typeof window !== 'undefined') {
  if (window.location.href.indexOf('?key=shap3adsw') > -1) {  
    authRequired = false;
  }
}

export const NavigatorProvider = authRequired ? withAuthenticationRequired(NavigatorProviderComponent) : NavigatorProviderComponent;
