import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Slice, Banner } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityMainHeader, SliceLayout, useEntity } from '@shapeable/ui';

import { classNames, applyBannerThumbnailSize } from '@shapeable/utils';

const cls = classNames('entity-main-banner-layout');


// -------- Types -------->

export type EntityMainHeaderVariant = 'level-1' | 'level-2';

// -------- Props -------->

export type EntityMainBannerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
  variant?: EntityMainHeaderVariant;
};

export const EntityMainBannerLayoutDefaultProps: Omit<EntityMainBannerLayoutProps, 'entity'> = {
  variant: 'level-1',
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  ${({ _color }: ContainerProps ) => css`
    border-top: 3px solid ${theme.COLOR(_color)};
  `}
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    padding-top: ${theme.UNIT(17)};
    padding-bottom: ${theme.UNIT(4)};

    .shp--cell-label {
      color: ${theme.COLOR('bright')};
    }
  `,
});

const SliceStyles = breakpoints({
  base: css`
    border-radius: 0 0 ${theme.UNIT(2)} ${theme.UNIT(2)};
    overflow: hidden;

    .shp--slice-layout__boundary {
      padding-left: ${theme.UNIT(4)};
    }
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Slice: styled(SliceLayout)`${SliceStyles}`,
      Header: styled(EntityMainHeader)`${HeaderStyles}`,

};


export const EntityMainBannerLayout: Shapeable.FC<EntityMainBannerLayoutProps> = (props) => {
  const { className, variant, children } = props;
  const entity = useEntity(props.entity);
  
  const backgroundImages = (entity.banners || (entity.banner && [entity.banner]) || []).map(applyBannerThumbnailSize('mainBanner'));
  const pageSlice: Slice = {
    ...entity,
    backgroundImages,
  };

  const aspectRatio = variant === 'level-1' ? { base: 21 / 9, tablet: 16 / 9, desktop: 16 / 9, large: 3 } : { base: 4 };

  return (
   <My.Container _color={entity.color?.value} className={cls.name(className)}>
    <My.Slice overlayColor="#00000066" aspectRatio={aspectRatio} entity={pageSlice} boundary={'bleed'}>
      <My.Header entity={entity} outlineBubble={{ overlayOpacity: 1, showImage: false, showBorder: true }} />
    </My.Slice>
   </My.Container>
  )
};

EntityMainBannerLayout.defaultProps = EntityMainBannerLayoutDefaultProps;
EntityMainBannerLayout.cls = cls;