import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, OrganisationsProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
  query OrganisationsQuery {
    platform {
      allOrganisations {
        id name url slug __typename _entityTypeName
        description { text(truncate: { words: 30 } ) }
        openGraph {
          title date description { plain }
          image { url thumbnails { small { id url } } }
        }
        type { slug name }
        logo { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
        countries { id slug name path }
        topics { 
          _schema { label pluralLabel }
          id name slug path 
          openGraph { id image { id url } }
        }
        trends {
          _schema { label pluralLabel }
          id name slug path 
          openGraph { id image { id url } }
        }
        location {
          __typename id slug name
          latitude longitude
          openGraph { id image { id url } }
        }
      }
    }
  }
`

export const OrganisationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allOrganisations' ) }>{children}</Provider>;
};