import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { animated } from '@react-spring/web';
import { classNames } from '@shapeable/utils';
const cls = classNames('text-arc');

// -------- Types -------->

export type TextArcProps = HasStyle & Classable & HasChildren & {
  id: string;
  ascending?: boolean; // determines if the text curves upward or downward
  fontSize?: number;
  color?: string;
  suffix?: React.ReactNode;
  marker?: React.ReactNode;

  debug?: boolean;
  r: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  onClick?: () => void;
};

export const TextArcDefaultProps: Omit<TextArcProps, 'id' | 'x1' | 'x2' | 'y1' | 'y2' | 'r'> = {
  ascending: true,
  debug: false,
  color: 'light',
  fontSize: 18,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type TextProps = {
  _fontSize: number;
  _color: string;
  isClickable: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TextStyles = breakpoints({
  base: css`
    ${({ _fontSize, _color, isClickable }: TextProps ) => css`
      font-size: ${_fontSize}px;
      font-weight: 600;
      fill: ${theme.COLOR(_color)};
      cursor: ${isClickable ? 'pointer' : 'default'};

      textPath {
        fill: ${theme.COLOR(_color)};
      }
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled(animated.g)<ContainerProps>`${ContainerStyles}`,
    Text: styled.text<TextProps>`${TextStyles}`,
};

export const TextArc: Shapeable.FC<TextArcProps> = (props) => {
  const { className, suffix, marker, debug, style, id, r, ascending, fontSize, color, children, x1, x2, y1, y2, onClick } = props;

  const pathId = `${id}-path`;

  const d = `M${x1} ${y1} A${r} ${r} 0 0 ${ascending ? '1' : '0'} ${x2} ${y2}`;

  return (
    <My.Container style={style} className={cls.name(className)}>
      <defs>
        <path id={pathId} d={d} fill="#000" stroke="#000"></path>
      </defs>
      {
        debug && 
        <path id={pathId} d={d} fill="#00000099" ></path>
      }
      <My.Text onClick={onClick} isClickable={!!onClick} _color={color} _fontSize={fontSize}>
        <textPath xlinkHref={`#${pathId}`} startOffset="50%" textAnchor="middle">
          <tspan>{children}</tspan>
          {suffix}
          {marker}
        </textPath>
      </My.Text>
    </My.Container>
  )
};

TextArc.cls = cls;
TextArc.defaultProps = TextArcDefaultProps;