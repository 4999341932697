import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, useLang, SidebarButton, useTrends, useContributeForm, Icon, usePageEntity } from '@shapeable/ui';
import { includes, without } from 'lodash';
import { useNavigatorMap } from '../../hooks/use-navigator-map';
import { NavigatorIcon } from './navigator-icon';
import { IconToggleButton } from './icon-toggle-button';
import { ToggleMultiButtons } from './toggle-multi-buttons';
import { classNames } from '@shapeable/utils';
import { useMapState, useMapUtils } from '@shapeable/maps';
import { FeedbackIconGlyph, OrganisationIcon, WorldMapIconGlyph } from '@shapeable/icons';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const NavigatorButtonStyles = breakpoints({
  base: css`
    display: flex;
  `,
  desktop: css`
    display: none;
  `
});

const NavigatorIconStyles = breakpoints({
  base: css`
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const GptButtonStyles = breakpoints({
  base: css`
    
  `,
});

const GptIconStyles = breakpoints({
  base: css`
    width: 28px;
    height: 28px;
    margin-bottom: 12px;
  `,
});

const MapButtonStyles = breakpoints({
  base: css`
  
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    display: none;
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: flex;
  `
});

const TogglesStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG('light', 2, 6)};
    padding-top: ${theme.UNIT(4)};
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 32px;
  `,
});

const ToggleStyles = breakpoints({
  base: css`
    cursor: pointer;
    margin-bottom: ${theme.UNIT(3)};
    font-family: ${theme.FONT('sans')};
  `,
});

const ToggleLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(10)};
    margin-bottom: ${theme.UNIT(2)};
    font-family: $${theme.FONT('serif')};
  `,
});

const ToggleMultiStyles = breakpoints({
  base: css`
    cursor: pointer;
  `,
});

const ContactButtonStyles = breakpoints({
  base: css`
    display: none;
  `,
  tablet: css`
    display: flex;
  `
});

const ContactStyles = breakpoints({
  base: css`
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
  `,
  
});

// -------- Components -------->

const My = {
  Container: React.Fragment,
    NavigatorButton: styled(SidebarButton)`${NavigatorButtonStyles}`,
      NavigatorIcon: styled(NavigatorIcon)`${NavigatorIconStyles}`,

    MapButton: styled(SidebarButton)`${MapButtonStyles}`,
      WorldMap: styled(WorldMapIconGlyph)`${WorldMapStyles}`,

      Toggles: styled.div`${TogglesStyles}`,
        ToggleLabel: styled(CellLabel)`${ToggleLabelStyles}`,
        Toggle: styled(IconToggleButton)`${ToggleStyles}`,
        ToggleMulti: styled(ToggleMultiButtons)`${ToggleMultiStyles}`,

    ContactButton: styled(SidebarButton)`${ContactButtonStyles}`,
      ContactIcon: styled(FeedbackIconGlyph)`${ContactStyles}`,

};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();
  const { showOnClick } = useMapUtils();
  const [ mapState, setMapState, patchMapState ] = useMapState();
  const { entityTypeNames = [] } = mapState;
  const navigatorMap = useNavigatorMap();
  const Innovations = useTrends();
  const contributeForm = useContributeForm();

  const entity = usePageEntity();
  const isExplorer = includes(['Topic', 'Trend'], entity?.entityTypeName) || entity.entityPath === '/navigator' || entity.slug === 'gpt';

  const entityTypeIsVisible: (entityTypeName: EntityTypeName) => boolean = 
    (entityTypeName) => includes(entityTypeNames, entityTypeName);

  const toggleEntityType = (type: EntityTypeName) => {
    const currentSet = entityTypeNames;
    if (entityTypeIsVisible(type)) {
      patchMapState({ entityTypeNames: without(currentSet, type), selectedEntity: null });
    } else {
      patchMapState({ entityTypeNames: [...currentSet, type] });
    }
  };

  return (
    <My.Container>
      {
        mapState.isVisible &&
        <My.Toggles>
          <My.ToggleLabel>{t('Show:')}</My.ToggleLabel>
          <My.Toggle onClick={() => { toggleEntityType('Organisation') }} isActive={entityTypeIsVisible('Organisation')} icon={<OrganisationIcon />}>{t('All Orgs')}</My.Toggle>
          <My.ToggleMulti items={Innovations} />
        </My.Toggles>
      }
      {
        !mapState.isVisible && !navigatorMap.isShown &&
        <My.MapButton onClick={showOnClick} icon={<My.WorldMap />}>{t('Explore Map')}</My.MapButton>
      }
      {
        isExplorer && !mapState.isVisible &&
        <My.NavigatorButton isDisclosed={navigatorMap.isShown} onClick={() => { navigatorMap.toggle() }} icon={<My.NavigatorIcon />}>{t(navigatorMap.isShown ? 'Hide Navigator' : 'Show Navigator')}</My.NavigatorButton>
      }
      {
        //Removing ContactButton from mobile temporarily. Takes up to much space with 4 sidebar buttons
        !mapState.isVisible && !navigatorMap.isShown &&
        <My.ContactButton onClick={contributeForm.showOnClick} icon={<My.ContactIcon />}>{t('Contact Us')}</My.ContactButton>
      }
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;