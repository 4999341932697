import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { OutlineEntityBubble, EntityTypeHeader, useEntity, useLink } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('entity-outline-number');

// -------- Types -------->

export type EntityOutlineNumberProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const EntityOutlineNumberDefaultProps: Omit<EntityOutlineNumberProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};
    align-items: center;

    transition: transform .1s ease;
    
    &:hover {
      filter: brightness(1.1);
      transform: scale(1.03);
    };
    
    &:active {
      transform: scale(.98);
    };
  `,
});


const OutlineBubbleStyles = breakpoints({
  base: css`
    flex-shrink: 0;
   
  `,
});

const TypeAndNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(1)};   
    margin-top: ${theme.UNIT(2)};
    justify-content: center;
  `,
});

const TypeStyles = breakpoints({
  base: css`
    
  `,
});

const NameStyles = breakpoints({
  base: css`
    line-height: 1.1em;
    margin: 0;
    font-size: ${20/16}em;
    font-weight: 500;
    font-weight: 400;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    TypeAndName: styled.div`${TypeAndNameStyles}`,
      Type: styled(EntityTypeHeader)`${TypeStyles}`,
      Name: styled.h1`${NameStyles}`,
    OutlineBubble: styled(OutlineEntityBubble)`${OutlineBubbleStyles}`,
};

export const EntityOutlineNumber: Shapeable.FC<EntityOutlineNumberProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { Link } = useLink();
  
  return (
   <My.Container className={cls.name(className)}>
      <Link href={entity.path} style={{ textDecoration: 'none', color: 'black' }}>
        <My.Body>
          <My.OutlineBubble showImage={false} entity={entity} />
          <My.Name>{entity.name}</My.Name>
        </My.Body>
      </Link>
   {children}
   </My.Container>
  )
};

EntityOutlineNumber.defaultProps = EntityOutlineNumberDefaultProps;
EntityOutlineNumber.cls = cls;