import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, cPointAtOrigin } from '@shapeable/utils';
import { animated } from '@react-spring/web';
const cls = classNames('node-marker');

// -------- Types -------->

export type NodeMarkerProps = HasStyle & Classable & HasChildren & {
  dotRadius?: number;
  lineDotRadius?: number;
  lineLength?: number; // the length between the mid-point of the dot and the mid-point of the lineDot
  angle?: number,
  id?: string;
  cx?: number,
  cy?: number,
  color?: string,
  onClick?: () => void;
}

export const NodeMarkerDefaultProps: NodeMarkerProps = {
  dotRadius: 4.25,
  lineDotRadius: 2,
  lineLength: 26,
  angle: 0,
  cx: 0,
  cy: 0,
  color: 'light',
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ _color }: ContainerProps ) => css`
      circle {
        fill: ${theme.COLOR(_color)};
      }
      
      line {
        stroke: ${theme.COLOR(_color)};
      }
    `}
  `,
});

const DotStyles = breakpoints({
  base: css`
    
  `,
});

const LineStyles = breakpoints({
  base: css`
    
  `,
});

const LineDotStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
    Dot: styled(animated.circle)`${DotStyles}`,
    Line: styled(animated.line)`${LineStyles}`,
    LineDot: styled(animated.circle)`${LineDotStyles}`,
};

export const NodeMarker: Shapeable.FC<NodeMarkerProps> = (props) => {
  const { className, lineLength, onClick, style, lineDotRadius, color, dotRadius, angle, cy, cx, id } = props;


  const totalRadius = lineLength + lineDotRadius; // we add the extra lineDotRadius as it is a cap for the line
  const width = totalRadius * 2;
  const height = totalRadius * 2;
  const origin = totalRadius;
  const viewBox = `0 0 ${totalRadius * 2} ${totalRadius * 2}`;
  const cPointAt = cPointAtOrigin(totalRadius, totalRadius);
  
  const [ lineX1, lineY1 ] = [origin, origin]
  const cLineDot = cPointAt(lineLength, angle);

  const x = cx - totalRadius;
  const y = cy - totalRadius;

  return (
    <My.Container onClick={onClick} style={style} id={id} width={width} height={height} x={x} y={y} _color={color} className={cls.name(className)} viewBox={viewBox}>
      <My.Dot r={dotRadius} cx={origin} cy={origin} />
      <My.Line x1={lineX1} y1={lineY1} x2={cLineDot.cx} y2={cLineDot.cy} />
      <My.LineDot r={lineDotRadius} cx={cLineDot.cx} cy={cLineDot.cy} />
    </My.Container>
  )
};

NodeMarker.cls = cls;
NodeMarker.defaultProps = NodeMarkerDefaultProps;