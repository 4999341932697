import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityExplorerLayout } from '../../components/entities/entity-explorer-layout';

import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-gpt');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const NavigatorGptLayoutStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    NavigatorLayout: styled(EntityExplorerLayout)`${NavigatorGptLayoutStyles}`,
};

export const PageLayoutGpt: PageLayoutComponent = 
(props) => {
  const { className, entity } = props;

  return (
    <My.Container className={cls.name(className)}>
      <EntityExplorerLayout entity={entity} />
    </My.Container>
  )
};