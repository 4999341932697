import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard, SiteHeaderProvider } from '@shapeable/web';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`

    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      max-width: 600px;
      
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      line-height: 1.3em;
    }
  `,
  desktop: css`

  `
});

const NavigatorStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      className={className}
      headerProps={{ variant: 'overlay' }}
    />
  )
};
