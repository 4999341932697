import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Trend } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentEntityIntro, ContentNode, ContentTitle, EntityCardGrid, EntityFilters, useEntity, useLang, useView } from '@shapeable/ui';
import { TabSpec, Tabs } from '../../components/elements/tabs';
import { compact, sortBy } from 'lodash';
import { horizonIntro } from '../../data';
import { HorizonCardGrid } from './horizon-card-grid';
import { EntityOutlineNumber } from './entity-outline-number';
import { EntityMainBannerLayout } from './entity-main-banner-layout';
import { IconHeading } from '../../components/elements/icon-heading';
import { CitationCard } from './citation-card';
import { COLOR_VALUE_LIGHT_BLUE_20 } from '../../theme';
import { GptLayout } from '@shapeable/gpt';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph, HorizonIcon } from '@shapeable/icons';
const cls = classNames('trend-main-layout');

// -------- Types -------->

export type TrendView = 'overview' | 'reports' | 'shapeAi';

export type TrendMainLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendMainLayoutDefaultProps: Omit<TrendMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(2)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: ${theme.COLOR('heading')};
    font-size: 1em;
    color: #5CACC3;
    text-transform: uppercase;
  `,
});

const TitleAndIconStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(2)};

  `,
});

const IconStyles = breakpoints({
  base: css`
    width: 48px;
    height: 48px;
  `,
});

const HorizonTypeStyles = breakpoints({
  base: css`
    
  `,
});

const FocusAreasListStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const FocusTitleStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
});

const FocusAreasItemsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.UNIT(5)};
    
  `,
});

const FocusAreaStyles = breakpoints({
  base: css`
    flex-basis: 45%;
    font-size: 0.8em;
  `,
});



const BannerStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.UNIT(4)};
  `,
});

const LibraryContainerStyles = breakpoints({
  base: css`
    border-radius: ${theme.UNIT(2)};
    background-color: ${COLOR_VALUE_LIGHT_BLUE_20};
    .shp--card__body {
      background: var(--shapeable-white, #FFF);
    }
  `,
  desktop: css`
    padding: ${theme.UNIT(3)};
  `
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const CitationsStyles = breakpoints({
  base: css`

  `,
  
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(EntityMainBannerLayout)`${BannerStyles}`,
    Tabs: styled(Tabs)`${TabsStyles}`,
    Body: styled.div`${BodyStyles}`,
    Section: styled.section`${SectionStyles}`,

      Title: styled(ContentTitle)`${TitleStyles}`,

      IconHeading: styled(IconHeading)``,
      Horizontype: styled(HorizonCardGrid)`${HorizonTypeStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,

      FocusAreasList: styled.div`${FocusAreasListStyles}`,
        FocusAreasTitle: styled(CellLabel)`${FocusTitleStyles}`,
        FocusAreasItems: styled.div`${FocusAreasItemsStyles}`,
          FocusArea: styled(EntityOutlineNumber)`${FocusAreaStyles}`,

      LibraryContainer: styled.div`${LibraryContainerStyles}`,
      Filters: styled(EntityFilters)`${FiltersStyles}`, 
      Reports: styled(EntityCardGrid)`${CitationsStyles}`,

      CitationCard: styled(CitationCard)``,
      
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

};

export const TrendMainLayout: Shapeable.FC<TrendMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, horizons = [], topics = [], citations = [], feedEntries = [] } = entity;

  const view = `trend-${entity.slug}`;
  const { resultsFor } = useView(view);

  const trendReports = [...citations, ...feedEntries];

  const t = useLang();

  const hasHorizons = !!horizons.length;
  const hasTopics = !!topics.length;
  const hasCitations = !!citations.length;
  const hasFeedEntries = !!feedEntries.length;
  const hasReports = !!trendReports.length;

  const tabs: TabSpec<TrendView>[] = compact([
    {
      id: 'shapeAi',
      label: 'ShapeAI',
      icon: <AiLowerCaseIconGlyph size={32} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
      hasReports && {
      id: 'reports',
      label: 'Resources',
      count: trendReports.length,
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(view, 'overview');

  const reports = sortBy([
    ...resultsFor({type: 'Citation', data: citations, by: 'name'}),
    ...resultsFor({type: 'FeedEntry', data: feedEntries, by: 'name'}),
  ], 'name');
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Banner variant="level-1" entity={entity} />
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} count={4} tabs={tabs} variant='suiss' />
      }
      {
        activeView === 'shapeAi' && 
        <My.GptLayout entity={entity} />
      }
      {
        activeView === 'overview' && 
        <My.Body>
          <My.Section>
            <My.Intro entity={entity} />
            <My.Description entity={description} />
          </My.Section>
        
          {
            hasHorizons && 
            <My.Section>
            <My.IconHeading icon={<HorizonIcon />}>{t('Horizons')}</My.IconHeading>
              <My.Description entity={horizonIntro}/>
              <My.Horizontype items={horizons} />
            </My.Section>
          }

        <My.Section>
          {
            hasTopics && 
              <My.FocusAreasList>
                <My.FocusAreasTitle>{t("Focus Areas:")}</My.FocusAreasTitle>
                <My.FocusAreasItems>
                {
                  topics.map(topic => <My.FocusArea key={topic.id} entity={topic} />)
                }
                </My.FocusAreasItems>
              </My.FocusAreasList>
          }
        </My.Section>
      </My.Body>
    }
      
      {
        activeView === 'reports' && 
        <>
          {
            entity?.intro?.text &&
            <My.Section>
              <My.Intro entity={entity} />
            </My.Section>
          }
          <My.Section>
            <My.LibraryContainer>
              <My.Filters 
                view={view}
                typeToggles={compact([
                  hasCitations &&
                  {
                    name: 'Citation',
                    label: 'External Reports',
                  },
                  hasFeedEntries &&
                  {
                    name: 'FeedEntry',
                    label: 'Articles',
                  },
                ])}
              />
              {
                hasReports && <My.Reports items={reports} spacing={3} maxColumns={3}/>
              }
            </My.LibraryContainer>
          </My.Section>
        </>
      }

      {children}
    </My.Container>
  )
};

TrendMainLayout.defaultProps = TrendMainLayoutDefaultProps;
TrendMainLayout.cls = cls;