import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, FileAsset } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Card, CellHeader, EntityCard, EntityCardProps, useEntity } from '@shapeable/ui';
import FILE_DATA from '../../images/file-data-image.png';
import { classNames } from '@shapeable/utils';
const cls = classNames('file-asset-card');

// -------- Types -------->

export type FileAssetCardProps = Classable & HasChildren & Omit<EntityCardProps, 'entity'> & { 
  entity?: FileAsset;
  label?: string;
  
};

export const FileAssetCardDefaultProps: Omit<FileAssetCardProps, 'entity'> = {
  label: 'File',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `
});

const FileTypeStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(3)};
  `,
});

const FileSizeStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(2)};
    
  `,
});


// -------- Components -------->

const My = {
  Card: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
    FileType: styled.span`${FileTypeStyles}`,
    FileSize: styled.span`${FileSizeStyles}`,
    
};

export const FileAssetCard: Shapeable.FC<FileAssetCardProps> = (props) => {
  const { className, children, label, ...rest } = props;
  const entity = useEntity(props.entity);

  const { file } = entity;
  const { formattedSize, typeName } = file;

  const url = file.url || "/pdf";
  const downloadUrl = url.replace(/upload\//, 'upload/fl_attachment/');

  const fileWithImage = {
    ...entity,
    openGraph: {
      image: {
        url: FILE_DATA,
      }
    }
  };


  return (
    <My.Card 
      {...rest} 
      labelFieldPath='types[0].name'
      href={downloadUrl || "/pdf"}
      target="_blank" 
      label={label}
      entity={fileWithImage}
      className={cls.name(className)}>
      <My.FileType>File Type: {typeName}</My.FileType>
      <My.FileSize>File Size: {formattedSize}</My.FileSize>
      {children}
    </My.Card>
  )
};

FileAssetCard.defaultProps = FileAssetCardDefaultProps;
FileAssetCard.cls = cls;