import { EntityBubbleImage } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, EntityTypeName, ImageEntity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';

const cls = classNames('icon-toggle-button');

// -------- Types -------->

export type ToggleButtonVariant = 'small' | 'default';

export const ToggleButtonImageSizes: Record<ToggleButtonVariant, number> = {
  small: 22,
  default: 30,
}

export const ToggleButtonFontSizes: Record<ToggleButtonVariant, number> = {
  small: 13,
  default: 16,
}

export type IconToggleButtonProps = Classable & HasChildren & { 
  entity?: Entity;
  isActive?: boolean;
  variant?: ToggleButtonVariant;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  showImage?: boolean;
};

export const IconToggleButtonDefaultProps: Omit<IconToggleButtonProps, 'icon'> = {
  isActive: false,
  onClick: () => {},
  showImage: false,
  variant: 'default',
};

// -------- Child Component Props -------->

type ContainerProps = {
  isActive: boolean;
}

type LabelProps = {
  variant: ToggleButtonVariant;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('condensed')};
    display: block;
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(11)};
    color: ${theme.COLOR('light')};
    ${theme.FILL('light')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    &:hover {
    }

    ${({ isActive }: ContainerProps ) => isActive && css`
      opacity: 1.0;
    `}

    svg {
      width: 32px;
      height: 32px;
    }
  `,
  tablet: css`
    width: 24px;
  `,
  desktop: css`
    width: 40px;
  `

});

const LabelStyles = breakpoints({
  base: css`
    display: flex;
    padding-top: ${theme.UNIT(1)};
    text-align: center;

    ${({ variant }: LabelProps) => css`
      font-size: ${ToggleButtonFontSizes[variant] ? (ToggleButtonFontSizes[variant]/16) : 1}em;
    `}
  `,
});

const ImageStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Label: styled.span<LabelProps>`${LabelStyles}`,
  Image: styled(EntityBubbleImage)`${ImageStyles}`,
};

export const IconToggleButton: React.FC<IconToggleButtonProps> = (props) => {
  const { className, children, entity, onClick, isActive, variant, showImage, icon } = props;

  const imageSize = ToggleButtonImageSizes[variant];
  
  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <My.Container onClick={handleClick} isActive={isActive} className={cls.name(className)}>
      {
        showImage ? <My.Image size={imageSize} entity={entity} /> : icon
      }
    <My.Label variant={variant}>
    {children}
    </My.Label>
    </My.Container>
  )
};

IconToggleButton.defaultProps = IconToggleButtonDefaultProps;