import React from "react";
import { useAppState, useLink, usePageEntity } from "@shapeable/ui";
import { Dictionary, Topic, Trend } from "@shapeable/types";
import { entityTypeNameFor } from "@shapeable/utils";

export type NavigatorTrendState = {
  head: number;
  tail: number;
} 


export type NavigatorMapState = {
  isShown?: boolean;

  // current state of each trend keyed by ID
  // we need this to manage the current head and tail angles of each trend 
  // these should only be used as a "transition from" state, the "to" state should be derived from the URL
  trendState: Dictionary<NavigatorTrendState>;
};


export const NavigatorDefaultState: NavigatorMapState = {
  isShown: false,
  trendState: {},
};

export type NavigatorMapDefinition = {
  state: NavigatorMapState;
  activeTrend: Trend;
  activeTopic: Topic;
  
  isInNavigator: boolean;
  
  isShown: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
  setActiveTrend: (trend: Trend) => void;
  setActiveTopic: (topic: Topic) => void;

  setTrends: (state: Dictionary<NavigatorTrendState>) => void;
};


export type NavigatorConfig = {
  defaultState: NavigatorMapState;
}

export const NavigatorDefaultConfig: NavigatorConfig = {
  defaultState: NavigatorDefaultState,
};


export const useNavigatorMap: () => NavigatorMapDefinition = () => {
 
  const [ appState, setAppState, patchAppState ] = useAppState();
  const navigatorMap = appState.navigatorMap || NavigatorDefaultState; 

  const entity = usePageEntity();

  const { navigate } = useLink();

  const typeName = entityTypeNameFor(entity);

  const show = () => patchAppState({ navigatorMap: { isShown: true } });
  const hide = () => patchAppState({ navigatorMap: { isShown: false } });
  const toggle = () => patchAppState({ navigatorMap: { isShown: !navigatorMap.isShown } }); 

  const activeTopic = 
    (typeName === 'Topic' ? entity : null);

  const setTrends = (state: Dictionary<NavigatorTrendState>) => {
    patchAppState({ navigatorMap: { trendState: state } });
  };

  const activeTrend = 
    (typeName === 'Trend' ? entity : null) || 
    (typeName === 'Topic' ? (entity as Topic).trend : null);

  const isInNavigator = !!(activeTrend || activeTopic);


  return {
    isShown: navigatorMap.isShown,
    state: navigatorMap,
    activeTrend,
    isInNavigator,
    activeTopic,
    toggle,
    show,
    hide,
    setTrends,
    setActiveTrend: (trend) => { 
      hide(); 
      navigate(trend.path);
      
    },
    setActiveTopic: (topic) => { hide(); navigate(topic.path) },
  }

}