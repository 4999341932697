import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Instrument } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCard, EntityCardProps, EntityValue, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('instrument-card');

// -------- Types -------->

export type InstrumentCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Instrument;
};

export const InstrumentCardDefaultProps: Omit<InstrumentCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    border: 1px solid #D2D2D2;
    border-top-left-radius: 24px;

    .shp--content {
      font-size: ${theme.FONT_SIZE(14)};
      line-height: 1.5;
    }

    .shp--card__content > .shp--cell-header:first-of-type {
      display: none;
    }

    .shp--entity-card__title {
      display: none;
    }
  `,
});

const InstrumentStyles = breakpoints({
  base: css`
    .shp--cell-header__label-and-badge {
      align-items: center;
    }

    .shp--cell-header {
      margin-bottom: 4px;
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
    Instrument: styled(EntityValue)`${InstrumentStyles}`,
};

export const InstrumentCard: Shapeable.FC<InstrumentCardProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);

  const { types = [] } = entity || {};

  const instrumentType = types[0]?.name;
  
  return (
   <My.Container {...rest} className={cls.name(className)}>
    <My.Instrument entity={entity} variant='default' badge={instrumentType} />
    {children}
   </My.Container>
  )
};

InstrumentCard.defaultProps = InstrumentCardDefaultProps;
InstrumentCard.cls = cls;