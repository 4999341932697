import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ActiveEntityProvider, ChatProvider, useActiveEntity, useDimensions, useElementDimensions, useEntity, useLang, useSiteSidebar } from '@shapeable/ui';
import { MobileSiteSidebar, SiteHeaderLayout, SiteHeaderProvider } from '@shapeable/web';
import { NavigatorMap } from '../elements/navigator-map';
import { PAGE_NAVIGATOR } from '../../data';
import { useNavigatorMap } from '../../hooks/use-navigator-map';
import { TrendMainLayout } from './trend-main-layout';
import { TopicMainLayout } from './topic-main-layout';
import { EntityExplorerIndexLayout } from './entity-explorer-index-layout';
import { EntityExplorerNavigation } from './entity-explorer-navigation';
import { LinearNavigationProvider } from '../providers/linear-navigation-provider';
import { NavigatorProvider } from '../providers/navigator-provider';

import SOURCE_TYPE_FILE_IMAGE from '../../images/file-data-image.png';
import { NavigatorGptLayout } from './navigator-gpt-layout';
import { ChatAutoMessagePrompt } from '@shapeable/types'; 
import { classNames } from '@shapeable/utils';

const cls = classNames('entity-explorer-layout');

// -------- Types -------->

export type EntityExplorerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const EntityExplorerLayoutDefaultProps: Omit<EntityExplorerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type LayoutProps = {
  chromeHeight: number;
};

type AsideProps = {
  showBackground?: boolean;
  isShown: boolean;
  sidebarWidth: number;
  chromeHeight: number;
};

type MainProps = {
  asideWidth?: number;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex-grow: 1;
  `,
});

const MapStyles = breakpoints({
  base: css`
  `,
});


const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  desktop: css`
    flex-direction: row;

    ${({ chromeHeight }: LayoutProps ) => css`
      height: calc(100vh - ${chromeHeight}px);
    `}

  `
});


const MainStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(4)} ${theme.UNIT(4)};
    flex-grow: 1;
    overflow-y: scroll;
  `,
  desktop: css`
    ${({ asideWidth }: MainProps ) => css`
        width: ${`calc(100% - ${asideWidth}px)`};
    `}
  `,
});


const AsideStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)};
    flex-shrink: 0;
    display: flex;
    position: fixed;
    z-index: 500;
    top: 0;
    box-sizing: border-box;
    transition: right 0.3s;
    ${({ sidebarWidth, isShown }: AsideProps ) => css`
      height: 100vh;
      width: calc(100vw - ${sidebarWidth}px);
      right: calc(${isShown ? `0vw + ${sidebarWidth}px` : `-100vw`});
    `}
    flex-direction: column;
    gap: ${theme.UNIT(4)};
    overflow: hidden;
    background: linear-gradient(to bottom, #B2D5D6 0px, #FFF 100%); 
  `,
  tablet: css`
    z-index: 300;
    ${({ sidebarWidth, isShown, chromeHeight }: AsideProps ) => css`
      width: calc(100vw - ${sidebarWidth}px);
      height: calc(100vh - ${chromeHeight}px);
      right: calc(${isShown ? `0vw + ${sidebarWidth}px` : `-100vw`});
    `}
  `,
  desktop: css`
    z-index: 1;
    position: static;
    width: 50%;
    max-width: 650px;
    background: none;
    ${({ showBackground }: AsideProps ) => showBackground && css`
      background: linear-gradient(to bottom, #B2D5D6 0px, #FFF 600px, #FFF 100%); 
    `}

  `,
  desktopLarge: css`
    gap: ${theme.UNIT(6)};
  `,
  desktopFullHD: css`
    max-width: 800px;
  `,
});

const IndexLayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    padding-top: ${theme.UNIT(5)};
  `,
});


const TrendLayoutStyles = breakpoints({
  base: css`

  `,
});

const TopicLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const BackgroundStyles = breakpoints({
  base: css`
    align-self: stretch;
    flex-grow: 1;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  `,
});

const NavigationStyles = breakpoints({
  base: css`
    
  `,
});


const NavigatorMapTitleStyles = breakpoints({
  base: css`
    margin: 0;
    font-size: ${16/16}em;
    font-weight: 500;
    text-align: center;
    color: ${theme.COLOR('light')};
    text-transform: uppercase;
  `,
  desktopLarge: css`
    font-size: ${20/16}em;
  `
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div<LayoutProps>`${LayoutStyles}`,
      Aside: styled.aside<AsideProps>`${AsideStyles}`,
        Navigation: styled(EntityExplorerNavigation)`${NavigationStyles}`,
        NavigatorMapTitle: styled.h2`${NavigatorMapTitleStyles}`,
        Map: styled(NavigatorMap)`${MapStyles}`,
          NavigatorGptLayout: styled(NavigatorGptLayout)`${GptLayoutStyles}`, 
      Main: styled.main<MainProps>`${MainStyles}`,
        IndexLayout: styled(EntityExplorerIndexLayout)`${IndexLayoutStyles}`,
        TrendLayout: styled(TrendMainLayout)`${TrendLayoutStyles}`,
        TopicLayout: styled(TopicMainLayout)`${TopicLayoutStyles}`,

    Background: styled.div`${BackgroundStyles}`,
        
};

export const EntityExplorerLayout: Shapeable.FC<EntityExplorerLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { banner } = entity;
  const map = useNavigatorMap();
  const sidebar = useSiteSidebar();
  const currentPage = useActiveEntity();

  const isGptLayout = currentPage?.slug === 'gpt' && !map.activeTopic && !map.activeTrend;

  const isIndex = !map.activeTrend && !map.activeTopic && !isGptLayout;

  const [ ref, asideDimensions ] = useDimensions();

  const dimensions = useElementDimensions();
  const t = useLang();
  // use the 100 fallback, which is the expected height. using dynamic element dimensions gives interim results 
  // when React renders, which can cause FOUC.
    
  const chromeHeight = (dimensions?.siteHeader?.height || 100) + (dimensions?.siteFooter?.height || 0);

  const autoPrompts: ChatAutoMessagePrompt[] = [
    {
      id: 'innovations', 
      type: 'text',
      useColor: true,
      values: { question: "What innovations are happening in <%= entity.name %>?" },
    },
    {
      id: 'invest',
      type: 'text',
      useColor: true,
      values: { question: "Why should I invest in <%= entity.name %>?" },
    },
  ];

  return (

    <ChatProvider value={{ 
      autoPrompts,
    }}>

    <NavigatorProvider>
    <SiteHeaderProvider value={{ variant: isIndex ? 'overlay' : 'light'}}>
      {
        map.isShown &&
        <MobileSiteSidebar />
      }

      <ActiveEntityProvider value={PAGE_NAVIGATOR}>
      <LinearNavigationProvider>
      <My.Container className={cls.name(className)}>

        <My.Background style={{ backgroundSize: 'cover', backgroundImage: isIndex && `url(${banner?.image?.url})` }}>
        <SiteHeaderLayout  />

        <My.Layout chromeHeight={chromeHeight}>
          <My.Aside ref={ref} showBackground={!isIndex} isShown={map.isShown} sidebarWidth={sidebar.width} chromeHeight={chromeHeight}>
            <My.Navigation />
            <My.NavigatorMapTitle>{t('Explore Areas of Content and Data')}</My.NavigatorMapTitle>
            <My.Map />
          </My.Aside>
          <My.Main asideWidth={asideDimensions?.width}>
          {
            isIndex &&
            <My.IndexLayout />
          } 
          {
            map.activeTrend && !map.activeTopic && 
            <My.TrendLayout entity={entity} />
          }
          {
            map.activeTopic &&
            <My.TopicLayout entity={entity} />
          } 
          {
            isGptLayout &&
            <My.NavigatorGptLayout entity={entity} />
          }
          </My.Main>
        </My.Layout>
      </My.Background>
      </My.Container>
      </LinearNavigationProvider>
      </ActiveEntityProvider>
    </SiteHeaderProvider>
    </NavigatorProvider>

    </ChatProvider>

  )
};

EntityExplorerLayout.defaultProps = EntityExplorerLayoutDefaultProps;
EntityExplorerLayout.cls = cls;