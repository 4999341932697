import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ChatProvider, ContentEntityIntro, IconTextValue, useEntity, useLang } from '@shapeable/ui';
import { ChatAutoMessagePrompt } from '@shapeable/types';
import SOURCE_TYPE_FILE_IMAGE from '../../images/file-data-image.png';
import { GptLayout } from '@shapeable/gpt';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';

const cls = classNames('navigator-gpt-layout');

// -------- Types -------->

export type NavigatorGptLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const NavigatorGptLayoutDefaultProps: Omit<NavigatorGptLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
 
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    border-top: 3px solid #B9D9DA;
    border-radius: 0px 0px 8px 8px;
    background: rgba(186, 217, 218, 0.40);
  `,
});

const IconHeaderStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(32)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`

    .shp--gpt-prompt {
      border-top: none;
      background: none;
    }
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${14/13}em;
    color: ${theme.COLOR('text')};
    line-height: 1.5em;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled.div`${HeaderStyles}`,
    IconHeader: styled(IconTextValue)`${IconHeaderStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Body: styled.div`${BodyStyles}`,
    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const NavigatorGptLayout: Shapeable.FC<NavigatorGptLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang()
  
  const autoPrompts: ChatAutoMessagePrompt[] = [
    {
      id: 'adsw',
      type: 'text',
      values: { question: "Tell me about Abu Dhabi Sustainability Week" },
    },
    {
      id: 'net-zero-navigator', 
      type: 'text',
      values: { question: t("Tell me more about the Net Zero Navigator") },
    },
    {
      id: 'purpose',
      type: 'text',
      values: { question: "What is the purpose of the Net Zero Navigator?" },
    },
  ];
  
  return (

    <ChatProvider value={{ 
      autoPrompts,
    }}>
      <My.Container className={cls.name(className)}>
      <My.Header>
        <My.IconHeader color='dark' iconSize={55} iconComponent={AiLowerCaseIconGlyph}>{t('NavigatorGPT')}</My.IconHeader>
        <My.Intro entity={entity} />
      </My.Header>
      {/* <My.Banner variant="level-2" entity={entity} /> */}
      <My.Body>
        <My.GptLayout />
      </My.Body>
      {children}
    </My.Container>
   </ChatProvider>
  )
};

NavigatorGptLayout.defaultProps = NavigatorGptLayoutDefaultProps;
NavigatorGptLayout.cls = cls;