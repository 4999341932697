import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Citation, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CitationCell, EntityTextValue, EntityTextValueList, useEntity, useLang, EntityCard, EntityCardProps } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('citation-card');

// -------- Types -------->

export type CitationCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Citation;
  label?: string;
  hasBorder?: boolean;
};

export const CitationCardDefaultProps: Omit<CitationCardProps, 'entity'> = {
  label: "Scientific Paper",
  hasBorder: false,
};

// -------- Child Component Props -------->

type CardProps = {
  hasBorder?: boolean;
}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    .shp--card__content {
      padding: ${theme.UNIT(5)};
      background: #FFF;
      line-height: 1.8em;

      .shp--citation-cell__number {
        display: none;
      }

      img {
        object-fit: contain;
      }

      .shp--cell-header > .shp--cell-header__label-and-badge > .shp--cell-label {
        line-height: 1.8em;
      }

      a {
        text-decoration: none;
        color: ${theme.COLOR('link')};

        &:hover {
          color: ${theme.COLOR('link-hover')};
        }
          
      }
      ${({ hasBorder }: CardProps ) => hasBorder && css`
        border: 2px dotted ${theme.COLOR('line-mid')};
        border-radius: 0 40px 0 0;
      `}
    }
  `,
  desktop: css`

    
  `
});

const LinkedStyles = breakpoints({
  base: css`
    
  `,
});

const LinkedListStyles = breakpoints({
  base: css`
    
  `,
});

const CellStyles = breakpoints({
  base: css`
    a {
      word-break: break-all; 
    }
  `,
});




// -------- Components -------->

const My = {
  Card: styled(EntityCard)<CardProps>`${CardStyles}`,
    Linked: styled(EntityTextValue)`${LinkedStyles}`,
    LinkedList: styled(EntityTextValueList)`${LinkedListStyles}`,

    Cell: styled(CitationCell)`${CellStyles}`,

};

export const CitationCard: Shapeable.FC<CitationCardProps> = (props) => {
  const { className, children, label, hasBorder, ...rest } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  return (
    <My.Card {...rest} spaceUnits={0} href={entity.url || ""} hasBorder={hasBorder} target="_blank" label={label} className={cls.name(className)}>
      {/* <My.Cell entity={entity} isExtended /> */}
      {children}
    </My.Card>
  )
};

CitationCard.defaultProps = CitationCardDefaultProps;
CitationCard.cls = cls;