import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, IconComponent, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { NavigatorIcon } from '../elements/navigator-icon';
import { Icon, useActiveEntity, useLink } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { ChevronLeftIconGlyph } from '@shapeable/icons';
const cls = classNames('grid-back-button');

// -------- Types -------->

export type EntityExplorerBackButtonProps = Classable & HasChildren & {
  entity?: Entity; // which entity should this back button return to?
  icon?: IconComponent;
  href?: string;
}

export const EntityExplorerBackButtonDefaultProps: EntityExplorerBackButtonProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(3)}; 
  `,
});

const ChevronIconStyles = breakpoints({
  base: css`
    
  `,
});



const BackButtonIconStyles = breakpoints({
  base: css`
    @keyframes spinRight {
      from { transform: rotate(0deg); }
      to { transform: rotate(180deg); }
    }
    
    @keyframes spinBack {
      from { transform: rotate(180deg); }
      to { transform: rotate(0deg); }
    }

    animation: spinBack 0.5s forwards;

    &:hover {
      animation: spinRight 0.5s ease-in-out forwards;
    }

    &:active {
      animation: spinBack 0.2s ease-in-out forwards;
    }

  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ChevronIcon: styled(Icon)`${ChevronIconStyles}`,
    BackButtonIcon: styled(Icon)`${BackButtonIconStyles}`,
};

export const EntityExplorerBackButton: Shapeable.FC<EntityExplorerBackButtonProps> = (props) => {
  const { className, href } = props;
  const { Link } = useLink();

  // if no entity is provided, the active entity is the safe bet, since we typically want all pages in the "Explorer" to show the index as the active nav state
  const activeEntity = useActiveEntity();

  const entity = props.entity || activeEntity;

  const Icon = props.icon || NavigatorIcon;
  
  return (
    <Link href={entity.path}>
    <My.Container className={cls.name(className)}>
      <My.ChevronIcon size={16} color="text"><ChevronLeftIconGlyph /></My.ChevronIcon>
      <My.BackButtonIcon size={32} color="text"><Icon /></My.BackButtonIcon>
    </My.Container>
    </Link>
  )
};

EntityExplorerBackButton.cls = cls;
EntityExplorerBackButton.defaultProps = EntityExplorerBackButtonDefaultProps;