import React from 'react';
import { IconComponent } from '@shapeable/types'

export const FundingIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="61" viewBox="0 0 62 61" fill="none">
    <g clip-path="url(#clip0_8423_20498)">
      <path d="M31 5.55189C44.7208 5.55189 55.8791 16.6068 55.8791 30.1895C55.8791 43.7722 44.7158 54.8271 31 54.8271C17.2841 54.8271 6.12082 43.7771 6.12082 30.1895C6.12082 16.6019 17.2792 5.55189 31 5.55189ZM31 0.364258C14.3661 0.364258 0.882324 13.7172 0.882324 30.1895C0.882324 46.6618 14.3661 60.0147 31 60.0147C47.6338 60.0147 61.1176 46.6618 61.1176 30.1895C61.1176 13.7172 47.6338 0.364258 31 0.364258Z" fill="black"/>
      <path d="M29.2922 44.7714V41.6605C25.8422 41.3549 23.5526 39.9676 21.843 38.1096L24.9227 35.3349C26.2187 36.6856 27.8233 37.6023 29.7366 37.8712V31.7839C24.6141 30.7327 22.5281 28.5019 22.5281 25.2199C22.5281 21.669 24.8856 19.2977 29.2922 18.7904V15.6123H33.1865V18.7904C35.9206 19.096 37.9387 20.1411 39.4384 21.834L36.3279 24.6088C35.5071 23.692 34.4147 22.9525 32.773 22.5797V28.1964C37.556 29.2781 39.8457 31.2094 39.8457 34.6565C39.8457 38.1035 37.7288 40.7804 33.1803 41.526V44.7714H29.286H29.2922ZM29.7366 27.518V22.5124C27.9962 22.8486 27.2432 23.7654 27.2432 25.0855C27.2432 26.4056 27.9962 27.0474 29.7366 27.5241V27.518ZM32.7792 32.4562V37.804C34.3838 37.364 35.1368 36.3127 35.1368 35.0293C35.1368 34.0453 34.7233 33.0307 32.7792 32.4562Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_8423_20498">
        <rect width="60.2353" height="59.6505" fill="white" transform="translate(0.882324 0.364258)"/>
      </clipPath>
    </defs>
  </svg>
);