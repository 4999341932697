import { CellLabel, ContentNode, EntityTooltip, EntityTooltipProps, EntityValue, LinkButton, useLang } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, Entity, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Organisation } from '@shapeable/adsw-types';
import { classNames } from '@shapeable/utils';
import { ExternalLinkIconGlyph } from '@shapeable/icons';
const cls = classNames('organisation-tooltip');

// -------- Types -------->

export type OrganisationTooltipProps = EntityTooltipProps & { 
  entity?: Organisation;
};

export const OrganisationTooltipDefaultProps: Omit<OrganisationTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}
type TrendAndTopicProps = {
  hasTrend?: boolean;
  hasTopic?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size ${theme.FONT_SIZE(14)};
    text-align: left;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    line-height: 1.35em;
    font-size: ${theme.FONT_SIZE(13)};
  `,
});

const TopicStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0;

    ${({ hasTrend }: TrendAndTopicProps ) => hasTrend && css`
      padding-top: ${theme.UNIT(2)};
    `}
  `,
});

const TrendStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0;

    ${({ hasTopic }: TrendAndTopicProps ) => hasTopic && css`
      ${theme.H_DOT_BG_END('line', 2, 4)};
      padding-bottom: ${theme.UNIT(2)};
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
 `,
});

const LabelStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    width: 100%;
    max-width: 300px;
    min-width: 50px;
    padding-top: ${theme.UNIT(3)};
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityTooltip)<ContainerProps>`${ContainerStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,

      Value: styled(EntityValue)`${ValueStyles}`,

    Button: styled(LinkButton)`${ButtonStyles}`,
    Body: styled.div`${BodyStyles}`,
};

export const OrganisationTooltip: React.FC<OrganisationTooltipProps> = (props) => {
  const { className, children, entity } = props;

  const { topics = [], trends = [], description, url } = entity;
  const t = useLang();

  const connections: Entity[] = [
    ...trends,
    ...topics,
  ]

  const hasDescription = description && !!description?.text;

  return (
    <My.Container imageStyle='logo' entity={entity} showDescription={false} onClick={() => null} className={cls.name(className)}>
      {hasDescription && <My.Description entity={description} />}
        <My.Body>
          {
            connections.map((connection, i) => (
              <My.Value entity={connection} variant='card' />
            ))
          }
          {
            url && <My.Button href={url} color='#278689' variant='slim' icon={ExternalLinkIconGlyph} shape='rounded'>{t('Website')}</My.Button>
          }
          {children}
        </My.Body>
    </My.Container>
  )
};

OrganisationTooltip.defaultProps = OrganisationTooltipDefaultProps;