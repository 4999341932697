// polar angles start from 3 o'clock on a clockface, but let's normalise them from 12 o'clock to make it easier to reason about
export const angleFrom3 = (angle: number) => (angle);
export const angleFrom12 = (angle: number) => (angle - 90);
export const angleFrom6 = (angle: number) => (angle + 90);
export const angleFrom9 = (angle: number) => (angle + 180);


export const normalizeAngle = (degrees: number) => {
  let result = degrees;
  while (result < 0) result += 360;
  // removed this, when all angles are positive the animation doesn't do backwards overlapping
  while (result > 359) result -= 360; 

  return result;
}

