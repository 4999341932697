import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, EntityTypeName } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Icon, SidebarButtonProps, useEntity, useLang } from '@shapeable/ui';
import { IconToggleButton, IconToggleButtonProps } from './icon-toggle-button';
import { includes, without } from 'lodash';
import { useMapState, useMapUtils } from '@shapeable/maps'; 
import { classNames } from '@shapeable/utils';
import { EntityIcon, TriangleDownIconGlyph, TriangleUpIconGlyph } from '@shapeable/icons';
const cls = classNames('sidebar-multi-buttons');

// -------- Types -------->

export const entityTypeToChildItems: string[] = [
  'Water', 'Agriculture', 'Energy', 'Mobility', 'Industry','Built Enironment'
];

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 0.5;
    transform: translateY(0);
  }
`;

const fadeOutSlideUp = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

export type ToggleMultiButtonsProps = Classable & HasChildren & IconToggleButtonProps & { 
  items: Entity[];
  onClick?: (type: EntityTypeName) => void;
};

export const ToggleMultiButtonsDefaultProps: ToggleMultiButtonsProps = {
  items: [],
  isActive: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ChildSidebarButtonProps = {
  delay: number; 
  active: boolean;
  wasActive: boolean;
};

type IconProps = {
  active: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  `,
});

const ParentToggleStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    
  `,
});

const TrendToggleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
  `,
});

const TogglesStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  `,
});

const TrendsToggleStyles = breakpoints({
  base: css`
      opacity: 0;
      transform: translateY(-100%);
      font-family: ${theme.FONT('sans')};

    ${({ active, delay }: ChildSidebarButtonProps) => active && css`
      animation: ${fadeInSlideDown} 0.5s ease forwards;
      animation-delay: ${delay}s;
      animation-fill-mode: both;

      &.icon-toggle-button-active {
        opacity: 1 !important;
      }
      
    `}
    ${({ active, delay, wasActive }: ChildSidebarButtonProps) => !active && wasActive && css`
      animation: ${fadeOutSlideUp} 0.2s ease backwards;
      animation-delay: ${delay}s;
    `}
    
    
  `,
});

const IconStyles = breakpoints({
  base: css`
    opacity: 0.5;
    padding-top: 4px;
    ${({ active }: IconProps) => active && css`
      opacity: 1;
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ParentToggle: styled.div`${ParentToggleStyles}`,
      TrendToggle: styled(IconToggleButton)`${TrendToggleStyles}`,
      Icon: styled(Icon)<IconProps>`${IconStyles}`,

    Toggles: styled.div`${TogglesStyles}`,
    TrendsToggle: styled(IconToggleButton)<ChildSidebarButtonProps>`${TrendsToggleStyles}`,
};

export const ToggleMultiButtons: Shapeable.FC<ToggleMultiButtonsProps> = (props) => {
  const { className, children, items } = props;
  
  const t = useLang();

  const [active, setActive] = React.useState(false);
  const previousActive = usePrevious(active); 
  const { showOnClick } = useMapUtils();
  const [ mapState, setMapState, patchMapState ] = useMapState();
  const { entityTypeNames = [] } = mapState;
  
  // function below ensures that items do not transition in/out on initial render
  function usePrevious(value: any) {
    const ref = React.useRef();

    React.useEffect(() => {
      ref.current = value;
    }, [value]); 
    
    return ref.current; 
  };

  const handleActive = () => {
    setActive(!active);
  };

  const entityTypeIsVisible: (entityTypeName: string) => boolean = 
    (entityTypeName) => includes(entityTypeNames, entityTypeName);

  const toggleEntityType = (type: any) => {
    const isVisible = entityTypeIsVisible(type);
  
    let newEntityTypeNames = [...entityTypeNames];
    
    if (type === 'Organisation') {
      newEntityTypeNames = isVisible ? without(newEntityTypeNames, type) : [...newEntityTypeNames, type];
    } else {
      newEntityTypeNames = isVisible ? without(newEntityTypeNames, type) : without([...newEntityTypeNames, type], 'Organisation');
    }
  
    patchMapState({
      entityTypeNames: newEntityTypeNames,
      selectedEntity: type === 'Organisation' && !isVisible ? null : type,
    });
  };
  
  return (
    <My.Container className={cls.name(className)} > 
      <My.ParentToggle>
        <My.TrendToggle onClick={handleActive} isActive={active} icon={<EntityIcon />}>{t('Systems')}</My.TrendToggle>
        <My.Icon size={24} active={active}>{active ? <TriangleUpIconGlyph /> : <TriangleDownIconGlyph /> }</My.Icon>
      </My.ParentToggle>
    <My.Toggles>
      {
        items.map((entity, index) => 
        <My.TrendsToggle 
          className={entityTypeIsVisible(entity.name) ? `icon-toggle-button-active` : 'icon-toggle-button'}
          onClick={() => { toggleEntityType(entity.name) }}
          key={entity.id} 
          entity={entity} 
          variant='small' 
          showImage
          active={active}
          wasActive={previousActive}
          delay={index * 0.1}
          >
            {entity.name}
        </My.TrendsToggle>
        )
      }
    </My.Toggles>
    {children}
    </My.Container>
  )
};

ToggleMultiButtons.defaultProps = ToggleMultiButtonsDefaultProps;
ToggleMultiButtons.cls = cls;