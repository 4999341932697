import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentTitle, Icon } from '@shapeable/ui';

// -------- Types -------->

export type IconHeadingProps = Classable & HasChildren & {
  icon: React.ReactNode;
  iconColor?: string;
  iconSize?: number;
  strongLabel?: string;
}

export const IconHeadingDefaultProps: Omit<IconHeadingProps, 'icon'> = {
  iconColor: 'dark',
  iconSize: 48,
};

// -------- Child Component Props -------->

type ContainerProps = {
  strongLabel?: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('heading')};
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1em;
    display: flex;
    align-items: center;

  `,
});

const IconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ContentTitle)<ContainerProps>`${ContainerStyles}`,
  Icon: styled(Icon)`${IconStyles}`,
};

export const IconHeading: React.FC<IconHeadingProps> = (props) => {
  const { className, children, iconSize, iconColor, strongLabel } = props;
  const icon = props.icon;

  const strongText = strongLabel && <strong>{strongLabel}&nbsp;</strong>;

  return (
    <My.Container className={className}>
      <My.Icon size={iconSize} color={iconColor}>{icon}</My.Icon>
      {strongText}{children}
    </My.Container>
  )
};

IconHeading.defaultProps = IconHeadingDefaultProps;