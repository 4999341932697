import React from 'react';
import { Classable, EntityTypeName, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  WARM_GREY, useDimensions, ElementDimensionsProvider, 
  useFullscreen, usePageEntity, useReady, useContributeForm,
  useOrganisations,
  EntityValue, 
} from '@shapeable/ui';

import { WebConfig, WebProvider, LayoutShell, SiteFooterLayout } from '@shapeable/web';
import { MapConfig, MapProvider, useMapUtils, EntityMapPanel } from '@shapeable/maps';
import { GptProvider } from '@shapeable/gpt';

import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { TechnologyIcon } from './elements/technology-icon';
import { InstrumentIcon } from './elements/instrument-icon';
import { LANG_STRINGS } from '../lang';
import { includes } from 'lodash';
import { COLOR_VALUE_BUILT_ENVIRONMENT, COLOR_VALUE_DARK_BLUE, LayoutStyles, themeOverrides, YELLOW, YELLOW_DARK, YELLOW_ORANGE } from '../theme';
import { 
  TrendsProvider, PagesProvider, HorizonTypesProvider, 
  CitationsProvider, TechnologiesProvider, InstrumentsProvider,
  InstrumentTypesProvider,
  OrganisationsProvider,
  OrganisationTypesProvider, FeedEntriesProvider, PostsProvider,
} from '../gatsby/providers';
import { FileAssetCard } from './entities/file-asset-card';
import { CitationCard } from './entities/citation-card';
import { SidebarButtons } from './elements/sidebar-buttons';
import { InstrumentTypeIcon } from './icons/instrument-type-icon';
import { OrganisationTooltip } from './entities/organisation-tooltip';
import { ContributeFormPanel } from './elements/contribute-form-panel';
import { TopicsProvider } from '../gatsby/providers/topics-provider';
import { Organisation } from '@shapeable/adsw-types';

import FILE_ASSET_IMAGE from '../images/file-data-image.png';

export type LayoutPropTypes = Classable & HasChildren & {
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const MapStyles = breakpoints({
  base: css`
    
  `,
});



const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});

const ContributeFormStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 800;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
    ContributeForm: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormStyles}`,
    Map: styled(EntityMapPanel)`${MapStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {

  const { className, children } = props;
  const fullscreen = useFullscreen();
  const contributeForm = useContributeForm();

  // configure packages
  
  const mapConfig: MapConfig = {
    initialMapState: {
      isVisible: false,
      isInitialized: false,
      zoom: 3.35,
      entityTypeNames: [ 'Organisation' ],
      latitude: 24.015,
      longitude: 41.644,
    },
    // this map requires special Trend filtering, so we need to turn off default entity type filtering
    applyEntityTypeFilters: () => false,
    items: () => {
      const { entityTypeIsVisible, entityTypeNames } = useMapUtils();
      const organisations = useOrganisations() as Organisation[];
      return React.useMemo(
        () => {
          // selection of "Organisation" means ALL orgs are selected, so don't filter anything. 
          // This works because this toggle is switched off when any innovation system is made active
          if (entityTypeIsVisible('Organisation')) {
            return organisations;
          }

          return organisations.filter(organisation => organisation?.trends.some(trend => entityTypeIsVisible(trend.name as EntityTypeName)));
        }, [ organisations, entityTypeNames ]
      );
    },
  };
  
  const webConfig: WebConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    header: {
      // menuItemIsAvailable: menuItemIsAvailable(auth),
      variant: 'overlay',
      boundary: fullscreen.isActive ? 'padding' : 'content',
      activeColor: YELLOW_DARK,
      hoverColor: YELLOW_DARK,
    },
    sidebar: {
      isAvailable: () => true,
      widthMenu: 56,
      width: 56,
      showFullscreenButton: true,
      color: COLOR_VALUE_DARK_BLUE,
      buttons: <SidebarButtons />,
    },
    footer: {
      // ref: footerRef,
      backgroundColor: WARM_GREY,
      verticalPadding: 'slim',
    },
    menuBar: {
      isInverted: false,
      backgroundColor: COLOR_VALUE_BUILT_ENVIRONMENT
    },
    theme: themeOverrides,
    entity: {
      images: {
        PdfFileAsset: {
          url: FILE_ASSET_IMAGE,
        },
      },
      labels: {
        Trend: 'Innovation System',
        Topic: 'Focus Area',
      },
      dataProviders: [
        TrendsProvider, TopicsProvider, CitationsProvider, TechnologiesProvider,
        InstrumentsProvider, HorizonTypesProvider, PagesProvider, 
        InstrumentTypesProvider, OrganisationsProvider, 
        OrganisationTypesProvider, FeedEntriesProvider, PostsProvider,
      ],
      icons: {
        Technology: TechnologyIcon,
        Instrument: InstrumentIcon,
        InstrumentType: InstrumentTypeIcon,
      },
      cards: {
        FileAsset: FileAssetCard,
        Citation: CitationCard,
      },
      tooltips: {
        Organisation: OrganisationTooltip,
      }
    }
  };
  
  const entity = usePageEntity();
  const isExplorer = includes(['Topic', 'Trend'], entity?.entityTypeName) || entity.entityPath === '/navigator' || entity.slug === 'gpt';

  // const headerWidth = headerDimensions?.width || 0; 

  const headerWidth = 0;

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (headerWidth > 968) {
        document.body.style.overflow = isExplorer ? 'hidden' : 'auto';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isExplorer, headerWidth]);

  return (
    <GptProvider>
    <WebProvider value={webConfig}>
    <MapProvider value={mapConfig}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }
      }>
      <My.Map />
      <My.ContributeForm isVisible={contributeForm.isVisible} />
      <My.Body>
      {children}
      </My.Body>
      {
        !isExplorer && 
        <My.Footer />
      }
      </My.Container>
    </MapProvider>
    </WebProvider>
    </GptProvider>
  );
};

Layout.defaultProps = LayoutDefaultProps;

