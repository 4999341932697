import React from 'react';
import { IconComponent } from '@shapeable/types'

export const PartnershipIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
    <g clip-path="url(#clip0_8423_20522)">
      <path d="M32 6.0659C46.5784 6.0659 58.4341 17.9269 58.4341 32.5C58.4341 47.0731 46.5731 58.9341 32 58.9341C17.4269 58.9341 5.5659 47.0784 5.5659 32.5C5.5659 17.9216 17.4216 6.0659 32 6.0659ZM32 0.5C14.3266 0.5 0 14.8266 0 32.5C0 50.1734 14.3266 64.5 32 64.5C49.6734 64.5 64 50.1734 64 32.5C64 14.8266 49.6734 0.5 32 0.5Z" fill="black"/>
      <path d="M27.6544 20.3231C27.6544 22.2444 26.1003 23.7985 24.179 23.7985C22.2577 23.7985 20.7036 22.2444 20.7036 20.3231C20.7036 18.4018 22.2577 16.8477 24.179 16.8477C26.1003 16.8477 27.6544 18.4018 27.6544 20.3231ZM24.179 18.5854C23.2216 18.5854 22.4413 19.3657 22.4413 20.3231C22.4413 21.2804 23.2216 22.0608 24.179 22.0608C25.1364 22.0608 25.9167 21.2804 25.9167 20.3231C25.9167 19.3657 25.1364 18.5854 24.179 18.5854Z" fill="black"/>
      <path d="M20.697 31.6283C20.697 29.707 22.2511 28.1529 24.1724 28.1529C26.0937 28.1529 27.6478 29.707 27.6478 31.6283H31.1233C31.1233 27.7857 28.0085 24.6709 24.1659 24.6709C20.3233 24.6709 17.2085 27.7857 17.2085 31.6283H20.6839H20.697Z" fill="black"/>
      <path d="M43.307 20.3231C43.307 22.2444 41.7529 23.7985 39.8316 23.7985C37.9103 23.7985 36.3562 22.2444 36.3562 20.3231C36.3562 18.4018 37.9103 16.8477 39.8316 16.8477C41.7529 16.8477 43.307 18.4018 43.307 20.3231ZM39.8316 18.5854C38.8742 18.5854 38.0939 19.3657 38.0939 20.3231C38.0939 21.2804 38.8742 22.0608 39.8316 22.0608C40.789 22.0608 41.5693 21.2804 41.5693 20.3231C41.5693 19.3657 40.789 18.5854 39.8316 18.5854Z" fill="black"/>
      <path d="M36.3496 31.6283C36.3496 29.707 37.9037 28.1529 39.825 28.1529C41.7463 28.1529 43.3004 29.707 43.3004 31.6283H46.7758C46.7758 27.7857 43.6611 24.6709 39.8185 24.6709C35.9758 24.6709 32.8611 27.7857 32.8611 31.6283H36.3365H36.3496Z" fill="black"/>
      <path d="M27.6544 36.8475C27.6544 38.7688 26.1003 40.3229 24.179 40.3229C22.2577 40.3229 20.7036 38.7688 20.7036 36.8475C20.7036 34.9262 22.2577 33.3721 24.179 33.3721C26.1003 33.3721 27.6544 34.9262 27.6544 36.8475ZM24.179 35.1098C23.2216 35.1098 22.4413 35.8901 22.4413 36.8475C22.4413 37.8049 23.2216 38.5852 24.179 38.5852C25.1364 38.5852 25.9167 37.8049 25.9167 36.8475C25.9167 35.8901 25.1364 35.1098 24.179 35.1098Z" fill="black"/>
      <path d="M20.697 48.1527C20.697 46.2314 22.2511 44.6773 24.1724 44.6773C26.0937 44.6773 27.6478 46.2314 27.6478 48.1527H31.1233C31.1233 44.3101 28.0085 41.1953 24.1659 41.1953C20.3233 41.1953 17.2085 44.3101 17.2085 48.1527H20.6839H20.697Z" fill="black"/>
      <path d="M43.307 36.8475C43.307 38.7688 41.7529 40.3229 39.8316 40.3229C37.9103 40.3229 36.3562 38.7688 36.3562 36.8475C36.3562 34.9262 37.9103 33.3721 39.8316 33.3721C41.7529 33.3721 43.307 34.9262 43.307 36.8475ZM39.8316 35.1098C38.8742 35.1098 38.0939 35.8901 38.0939 36.8475C38.0939 37.8049 38.8742 38.5852 39.8316 38.5852C40.789 38.5852 41.5693 37.8049 41.5693 36.8475C41.5693 35.8901 40.789 35.1098 39.8316 35.1098Z" fill="black"/>
      <path d="M36.3496 48.1527C36.3496 46.2314 37.9037 44.6773 39.825 44.6773C41.7463 44.6773 43.3004 46.2314 43.3004 48.1527H46.7758C46.7758 44.3101 43.6611 41.1953 39.8185 41.1953C35.9758 41.1953 32.8611 44.3101 32.8611 48.1527H36.3365H36.3496Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_8423_20522">
        <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
);