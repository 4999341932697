import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityExplorerLayout } from '../../components/entities/entity-explorer-layout';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const EntityNavigatorStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    EntityExplorer: styled(EntityExplorerLayout)`${EntityNavigatorStyles}`,
};

export const PageLayoutNavigator: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;
  return (
    <EntityExplorerLayout />
  )
};
