import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, cPointAtOrigin } from '@shapeable/utils';
import { NAVIGATOR_MAP_INNER_RADIUS, NAVIGATOR_MAP_OUTER_RADIUS } from '../../data';
const cls = classNames('donut-slice');

import { lighten } from 'polished';

import { animated, useSpring, to, easings, AnimationResult, Controller, SpringValue } from '@react-spring/web';
import { normalizeAngle } from '../../utils';

// -------- Types -------->

export type DonutSliceProps = HasStyle & Classable & HasChildren & {
  outerRadius?: number;
  innerRadius?: number;
  id: string;
  color?: string;
  highlightOnHover?: boolean;
  headAngle: number;
  headSize: number;
  onClick?: () => void;
  tailAngle: number;
  duration: number,
  startHeadAngle?: number;
  startTailAngle?: number;

  onRest?: (result: AnimationResult, spring: Controller | SpringValue<any>, item?: any) => void;
}

export const DonutSliceDefaultProps: Omit<DonutSliceProps, 'id' | 'headAngle' | 'tailAngle' | 'headSize'> = {
  outerRadius: NAVIGATOR_MAP_OUTER_RADIUS,
  innerRadius: NAVIGATOR_MAP_INNER_RADIUS,
  color: 'black',
  duration: 1000,
  highlightOnHover: true,
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color?: string;
}

type PathProps = {
  _color?: string;
  isClickable?: boolean;
  highlightOnHover: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});


const ShapeStyles = breakpoints({
  base: css`
    transition: fill 0.2s;
    ${({ _color, isClickable }: PathProps ) => css`
      fill: ${_color};
      cursor: ${isClickable ? 'pointer' : 'default'};
    `}

    ${({ highlightOnHover, _color }: PathProps ) => highlightOnHover && css`
       &:hover {
        fill: ${lighten(0.05, _color)};
      }
    `}

  `,
});


// -------- Components -------->

const My = {
  Container: styled(animated.svg)<ContainerProps>`${ContainerStyles}`,
    Path: styled(animated.path)<PathProps>`${ShapeStyles}`,
};

export const DonutSlice: Shapeable.FC<DonutSliceProps> = (props) => {
  const { 
    className, outerRadius, innerRadius, id, color, style, onClick,
    headAngle, tailAngle, startHeadAngle, startTailAngle, duration, children,
    highlightOnHover,
  } = props;

  const size = outerRadius * 2;
  const originX = outerRadius;
  const originY = outerRadius;
  const segmentRadius = (outerRadius - innerRadius) / 2;

  const cPointAt = cPointAtOrigin(originX, originY);
  const hasStartingValues = startHeadAngle !== undefined && startTailAngle !== undefined;

  const values = !hasStartingValues ? { headAngle, tailAngle } : 
    useSpring({
    from: {
      headAngle: startHeadAngle,
      tailAngle: startTailAngle,
    },
    to: {
      headAngle,
      tailAngle,
    },
    config: { tension: 130, friction: 13, bounce: 0 },
//  config: { duration, easing: easings.easeOutCirc  },
    onRest: props.onRest,
  });


  const shapeDef = (headAngle: any, tailAngle: any) => {
    
    // define the major points along the path. The shape we're building is shown in Figma here:
    // https://www.figma.com/file/mchQRDH6rkPA9ufK91Glbt/ADSW?type=design&node-id=1136-13476&mode=design&t=DWWEg9BwCrCcvKZT-4

    const tailCutoutStart = cPointAt(innerRadius, tailAngle);
    const tailCutoutEnd = cPointAt(outerRadius, tailAngle);
    const headStart = cPointAt(outerRadius, headAngle);
    const headEnd = cPointAt(innerRadius, headAngle);

    // the large arc flag needs to change depending on the angle difference between the tail and head
    // else it produces incorrect arcs for angles > 180 degrees
    const difference = headAngle - tailAngle;

    return `
      M${tailCutoutStart.cx},${tailCutoutStart.cy} 
      A${segmentRadius} ${segmentRadius} 0 0 0 ${tailCutoutEnd.cx} ${tailCutoutEnd.cy} 
      A${outerRadius} ${outerRadius} 0 ${difference > 180 ? `1` : `0`} 1 ${headStart.cx} ${headStart.cy}
      A${segmentRadius} ${segmentRadius} 0 1 1 ${headEnd.cx} ${headEnd.cy}
      A${innerRadius} ${innerRadius} 0 ${difference > 180 ? `1` : `0`} 0 ${tailCutoutStart.cx} ${tailCutoutStart.cy} z
    `;
  };

  return (
    <My.Container width={size} height={size} id={`slice-${id}`} style={style} _color={color} className={cls.name(className)} viewBox={`0 0 ${size} ${size}`}>
      <My.Path 
        highlightOnHover={highlightOnHover}
        _color={color}
        isClickable={!!onClick}
        d={to([values.headAngle, values.tailAngle], shapeDef)} 
        onClick={onClick} 
        className="shape"
      />
      {children}
    </My.Container>
  )
};

DonutSlice.cls = cls;
DonutSlice.defaultProps = DonutSliceDefaultProps;