import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { OrganisationTypesProvider as Provider, platformProviderValue } from '@shapeable/ui';

const ORGANISATION_TYPES_QUERY = graphql`
  query OrganisationTypesQuery {
    platform {
      allOrganisationTypes {
        __typename _schema { label pluralLabel }
        id name path slug
        openGraph { image { url } }
      }
    }
  }`;

export const OrganisationTypesProvider: React.FC<any> = ({ children }) => {
  /* @ts-ignore */
  return <Provider value={ platformProviderValue( useStaticQuery(ORGANISATION_TYPES_QUERY), 'allOrganisationTypes' ) }>{children}</Provider>;
};