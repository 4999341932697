import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Instrument, Organisation, Technology, Topic, } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  ContentEntityIntro, ContentNode, ContentTitle, EntityCardGrid, EntityFilters, EntityGrid, 
  OrganisationMarketMapGrid, 
  useEconomies, useEntity, useLang, useOrganisationTypes, useOrganisations, useView 
} from '@shapeable/ui';
import { TabSpec, Tabs } from '../../components/elements/tabs';
import { compact, sortBy } from 'lodash';
import { EntityMainBannerLayout } from './entity-main-banner-layout';
import { TechnologyContentSource } from './technology-content-source';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { useInstruments } from '../../hooks/use-instruments';
import { useTechnologies } from '../../hooks/use-technologies';
import { PolicyIcon } from '../../components/elements/policy-icon';
import { FundingIcon } from '../../components/elements/funding-icon';
import { PartnershipIcon } from '../../components/elements/partnership-icon';
import { useInstrumentTypes } from '../../hooks/use-instrument-types';
import { COLOR_VALUE_LIGHT_BLUE_20 } from '../../theme';
import { InstrumentCard } from './instrument-card';
import { IconContentReveal } from '../../components/elements/icon-content-reveal';
import { GptLayout } from '@shapeable/gpt';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('topic-main-layout');

// -------- Types -------->

export type TopicView = 'shapeAi' | 'overview' | 'technologies' | 'instruments' | 'stakeholders' | 'library';

export type TopicMainLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicMainLayoutDefaultProps: Omit<TopicMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type NoResultsProps = {
  NoResult: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;

  `,
});

const TabsStyles = breakpoints({
  base: css`
   padding-top: ${theme.UNIT(1)};
   margin-top: ${theme.UNIT(2)}; 
   margin-bottom: ${theme.UNIT(0)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const StakeholderSectionStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});



const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: #5CACC3;
    font-size: 1em;
    margin-bottom: 0;
    text-transform: uppercase;
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const TechnologiesListStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(12)};
  `,
});

const TechnologyStyles = breakpoints({
  base: css`
    
  `,
});

const InstrumentsListHeaderStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(4)};
  `,
});

const InstrumentLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
  `,
});

const InstrumentFiltersStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
  `,
});



const InstrumentsListStyles = breakpoints({
  base: css`
    
  `,
});

const InstrumentsStyles = breakpoints({
  base: css`
    
  `,
});

const StakeholderGridStyles = breakpoints({
  base: css`
    background-color: ${COLOR_VALUE_LIGHT_BLUE_20};
  `,
  desktop: css`
    padding: ${theme.UNIT(3)};
    border-radius: ${theme.UNIT(2)};
`
});

const IconContentRevealStyles = breakpoints({
  base: css`
    
  `,
});

const ContentTrendsStyles = breakpoints({
  base: css`
    ${DescriptionStyles};
  `,
});

const LibraryContainerStyles = breakpoints({
  base: css`
    background-color: ${COLOR_VALUE_LIGHT_BLUE_20};
    .shp--card__body {
      background: var(--shapeable-white, #FFF);
    }
  `,
  desktop: css`
    padding: ${theme.UNIT(3)};
    padding-bottom: 0;
    border-radius: ${theme.UNIT(2)};
  `
});

const LibraryGridStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;

  `,
});

const NoResultsMessageStyles = breakpoints({
  base: css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${theme.UNIT(12)};
  width: 100%;
  font-weight: 500;
  height: 80px;

  ${({ NoResult }: NoResultsProps) => (NoResult && css`
    background-color: ${COLOR_VALUE_LIGHT_BLUE_20};
  `)}
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(EntityMainBannerLayout)`${BannerStyles}`,
    Tabs: styled(Tabs)`${TabsStyles}`,

    Body: styled.div`${BodyStyles}`,

    Section: styled.section`${SectionStyles}`,
      Title: styled(ContentTitle)`${TitleStyles}`, 
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,

      IconContentReveal: styled(IconContentReveal)`${IconContentRevealStyles}`,
      ContentTrends: styled(ContentNode)`${ContentTrendsStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`, 

      TechnologiesList: styled.div`${TechnologiesListStyles}`,
        Technology: styled(TechnologyContentSource)`${TechnologyStyles}`,

      InstrumentsListHeader: styled.div`${InstrumentsListHeaderStyles}`,
        InstrumentLabel: styled.span`${InstrumentLabelStyles}`,
        InstrumentFilters: styled(EntityFilters)`${InstrumentFiltersStyles}`,
      InstrumentsList: styled.div`${InstrumentsListStyles}`,
        Instruments: styled(EntityGrid)`${InstrumentsStyles}`,

      StakeholderSection: styled.div`${StakeholderSectionStyles}`,
        StakeholderGrid: styled(OrganisationMarketMapGrid)`${StakeholderGridStyles}`,

      LibraryContainer: styled.div`${LibraryContainerStyles}`,
      LibraryGrid: styled(EntityCardGrid)`${LibraryGridStyles}`,

      NoResultsMessage: styled.span<NoResultsProps>`${NoResultsMessageStyles}`,

      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const TopicMainLayout: Shapeable.FC<TopicMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, technologies: technologiesEntity = [], instruments: instrumentsEntity = [], 
  organisations = [], fundingTrends, policyTrends, partnershipTrends, technologiesIntro, instrumentsIntro,
  stakeholdersIntro, libraryIntro, posts = [], citations = [], feedEntries = [], fileAssets = [] } = entity;

  const libraryItems = [...citations, ...posts, ...feedEntries, ...fileAssets];

  const hasTechnologies = !!technologiesEntity.length;
  const hasInstruments = !!instrumentsEntity.length;
  const hasStakeholders = !!organisations.length;
  const hasLibraryItems = !!libraryItems.length;

  const hasFundingTrends = !!fundingTrends?.text;
  const hasPolicyTrends = !!policyTrends?.text;
  const hasPartnershipTrends = !!partnershipTrends?.text;
  const hasTechnologiesIntro = !!technologiesIntro?.text;
  const hasInstrumentsIntro = !!instrumentsIntro?.text;
  const hasStakeholdersIntro = !!stakeholdersIntro?.text;
  const hasLibraryIntro = !!libraryIntro?.text;

  const allInstruments = useInstruments();
  const allTechnologies = useTechnologies();
  const allOrganisationTypes = useOrganisationTypes();
  const allEconomies = useEconomies();
  const allInstrumentTypes = useInstrumentTypes();
  const allOrganisations = useOrganisations();

  const t = useLang();

  const hasOrganisations = !!organisations.length;
  const hasEconomies = organisations.some(organisation => organisation?.countries?.length);
  const hasTechInstruments = technologiesEntity.flatMap(tech => tech.instruments);
  const hasInstrumentTypes = allInstruments.flatMap(instrument => instrument?.types);

  // augmenting Technologies as request was made to have the feedEntries associated with the 
  // technology match the Topic feedEntries
  const filteredTechnologyArticles = technologiesEntity.map(tech => {
    const filteredFeedEntries = tech?.feedEntries.filter(techFeed => 
      feedEntries.some(feed => techFeed.id === feed.id)
    );
    return {
      ...tech,
      feedEntries: filteredFeedEntries
    }
  });

  const view = `topic-${entity.slug}`;
  const { resultsFor, filters, types: toggledTypes } = useView(view);
  const { technologies = [], instruments = [], type = [], types = [], economies = [] } = filters;

  const tabs: TabSpec<TopicView>[] = compact([
    {
      id: 'shapeAi',
      label: 'ShapeAI',
      icon: <AiLowerCaseIconGlyph size={32} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    hasTechnologies && {
      id: 'technologies',
      label: 'Technologies',
      count: filteredTechnologyArticles.length,
    },
    hasInstruments && {
      id: 'instruments',
      label: 'Instruments',
      count: instrumentsEntity.length,
    },
    hasOrganisations && {
      id: 'stakeholders',
      label: 'Stakeholders',
      count: organisations.length,
    },
    hasLibraryItems && {
      id: 'library',
      label: 'Resources',
      count: libraryItems.length,
    },
  ]);
  
  const { activeView, setActiveView } = useView<TopicView>(view, 'overview');

  const filteredTechnologies = filteredTechnologyArticles.filter(technology => (
    linkedMatchesFilter(compact([technology]), technologies) &&
    linkedMatchesFilter(technology.instruments, instruments)
  ));

  const filteredStakeholders = organisations.filter(organisation => (
    linkedMatchesFilter(compact([organisation?.type]), type) &&
    linkedMatchesFilter(organisation?.countries, economies)
  ));

  const hasFilteredStakeholders = !!filteredStakeholders.length;

  const instrumentResults = instrumentsEntity.filter(instrument => (
    linkedMatchesFilter(instrument?.types, types)
  ));

  const library = sortBy([
    ...resultsFor({type: 'Post', data: posts, by: 'name'}),
    ...resultsFor({type: 'FileAsset', data: fileAssets, by: 'name'}),
    ...resultsFor({type: 'FeedEntry', data: feedEntries, by: 'name'}),
    ...resultsFor({type: 'Citation', data: citations, by: 'name'}),
  ], 'name');

  return (
    <My.Container className={cls.name(className)}>
    <My.Banner variant="level-2" entity={entity} />
    {
      tabs.length > 1 &&
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} count={4} tabs={tabs} variant='suiss' />
    }
    <My.Body>
    {
      activeView === 'shapeAi' && 
      <My.GptLayout entity={entity} />
    }
    {
      activeView === 'overview' && 
        <>
          <My.Section>
            <My.Intro entity={entity} />
            <My.Description entity={description} />
          </My.Section>
        </>
    }
    {
      activeView === 'technologies' &&
      <>
        <My.Section>
          <My.Title>{t("Current Practices, Technologies, and Innovations")}</My.Title>
          {
            hasTechnologiesIntro && <My.Description entity={technologiesIntro} />
          }
        </My.Section>

        <My.Section>
          <My.Title>{t("Explore Technologies")}</My.Title>
          <My.Filters
          results={filteredTechnologyArticles}
          view={view}
          selects={compact([
            !!allTechnologies.length && {
              name: 'technologies',
              items: allTechnologies, 
              pluralLabel: 'Technologies',
              getResultFieldSlugs: (result: Technology) => [result?.slug],
            },
            !!hasTechInstruments.length && {
              name: 'instruments',
              items: allInstruments, 
              pluralLabel: 'Instruments',
              getResultFieldSlugs: (result: Technology) => result?.instruments.map(instrument => instrument?.slug),
            }
          ])}
          />
        <My.TechnologiesList>
          {
            hasTechnologies &&
            !!filteredTechnologies.length ? 
            filteredTechnologies.map(technology => <My.Technology key={technology.id} entity={technology} />) 
            : <My.NoResultsMessage NoResult={false}>{t('No results found')}</My.NoResultsMessage> 
          }
        </My.TechnologiesList>
        </My.Section>
      </>
    }
    {
      activeView === 'instruments' &&
      <>
        <My.Section>
          {
            hasInstrumentsIntro && <My.Description entity={instrumentsIntro} />
          }
        </My.Section>
          {
            hasPolicyTrends &&  <My.IconContentReveal description={policyTrends} icon={<PolicyIcon />}>{t("Policy")}</My.IconContentReveal>
          }
          {
            hasFundingTrends && <My.IconContentReveal description={fundingTrends} icon={<FundingIcon />}>{t("Funding")}</My.IconContentReveal>
          }
          {
            hasPartnershipTrends && <My.IconContentReveal description={partnershipTrends} icon={<PartnershipIcon />}>{t("Partnership")}</My.IconContentReveal>
          }

        <My.Section>
          <My.InstrumentsListHeader>
            <My.InstrumentLabel>{t('Instruments:')}</My.InstrumentLabel>
            <My.InstrumentFilters
            results={instrumentsEntity}
            view={view}
            selects={compact([
              hasInstrumentTypes && {
                name: 'types',
                items: allInstrumentTypes, 
                pluralLabel: 'Instrument Types',
                getResultFieldSlugs: (result: Instrument) => result?.types?.map(type => type?.slug),
              }
            ])}
            />
          </My.InstrumentsListHeader>
        <My.InstrumentsList>
          {
            hasInstruments &&
            <My.Instruments items={instrumentResults} card={InstrumentCard} maxColumns={2} spacing={4} />
          }
        </My.InstrumentsList>
        </My.Section>
      </>
    }
    {
        activeView === 'stakeholders' && 
        <>
        <My.StakeholderSection>
          {
            hasStakeholdersIntro && <My.Description entity={stakeholdersIntro} />
          }
          <My.Title>{t("Market Map")}</My.Title>
          <My.Filters 
            results={organisations}
            view={view}
            selects={compact([
              {
                name: 'type',
                items: allOrganisationTypes, 
                pluralLabel: 'Stakeholder Types',
                getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
              },
              hasEconomies && {
                name: 'economies',
                items: allEconomies, 
                pluralLabel: 'Countries',
                getResultFieldSlugs: (result: Organisation) => result.countries?.map(country => country?.slug),
              }
            ])}
          />
        </My.StakeholderSection>

        <My.Section>
        {
          hasOrganisations && 
          filteredStakeholders.length ? <My.StakeholderGrid items={filteredStakeholders} /> :
          <My.NoResultsMessage NoResult={!filteredStakeholders.length}>{t('No results found')}</My.NoResultsMessage>
        }
        </My.Section>
        </>
      }
      {
        activeView === 'library' && 
        <>
          {
          hasLibraryIntro && 
          <My.Section>
          {
            <My.Description entity={libraryIntro} />
          }
          </My.Section>
          }

          <My.Section>
            <My.LibraryContainer>
              <My.Filters 
                view={view}
                typeToggles={compact([
                  !!posts.length && {
                    name: 'Post',
                    label: 'Posts',
                  },
                  !!fileAssets.length && {
                    name: 'FileAsset',
                    label: 'Reports',
                  },
                  !!feedEntries.length && {
                    name: 'FeedEntry',
                    label: 'Articles',
                  },
                  !!citations.length && {
                    name: 'Citation',
                    label: 'External Articles',
                  }, 
                ])}
              />

              <My.LibraryGrid items={library} maxColumns={3} spacing={2} />
            </My.LibraryContainer>
          </My.Section>
        </>
      }
      </My.Body>
      
   </My.Container>
  )
};

TopicMainLayout.defaultProps = TopicMainLayoutDefaultProps;
TopicMainLayout.cls = cls;