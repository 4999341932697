import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { DottedLine, LinearNavigation, useEntity } from '@shapeable/ui';
import { EntityExplorerBackButton } from './entity-explorer-back-button';
import { classNames } from '@shapeable/utils';

const cls = classNames('entity-grid-details-navigation');

// -------- Types -------->

export type EntityExplorerNavigationProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const EntityExplorerNavigationDefaultProps: Omit<EntityExplorerNavigationProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(4)};
  `,
});

const GridBackButtonStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  `,
});

const LinearNavigationStyles = breakpoints({
  base: css`
    flex-grow: 1;
    flex-basis: 80%;
    
    ${LinearNavigation.cls.dot('select')} {
      width: 100%;
    }

    ${LinearNavigation.cls.dot('prev-button')} {
      padding-left: 0;
    }

    ${LinearNavigation.cls.dot('next-button')} {
      padding-right: 0;
    }

  `,
});

const DividerStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    GridBackButton: styled(EntityExplorerBackButton).attrs(cls.attr('grid-back-button'))`${GridBackButtonStyles}`,
    Divider: styled(DottedLine)`${DividerStyles}`,
    LinearNavigation: styled(LinearNavigation).attrs(cls.attr('linear-navigation'))`${LinearNavigationStyles}`,
};

export const EntityExplorerNavigation: Shapeable.FC<EntityExplorerNavigationProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  return (
   <My.Container className={cls.name(className)}>
      <My.GridBackButton />
      <My.Divider width={2} />
      <My.LinearNavigation variant="default" value={entity.path} />
   </My.Container>
  )
};

EntityExplorerNavigation.defaultProps = EntityExplorerNavigationDefaultProps;
EntityExplorerNavigation.cls = cls;