import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { TopicsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const TOPICS_QUERY = graphql`
  query TopicsQuery {
    platform {
      allTopics {
        __typename _schema { label pluralLabel }
        id path slug name gptName dataSetId
        openGraph { description { text } image { url } }
      }
    }
  }`;

export const TopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(TOPICS_QUERY), 'allTopics' ) }>{children}</Provider>;
};
