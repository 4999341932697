import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode, Organisation, Page, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentEntityIntro, ContentNode, EntityValuePill, IconTextValue, OrganisationPoweredBy, SliceJumpLinks, useEntity, useLang, useLink, usePages } from '@shapeable/ui';
import NET_ZERO_IMAGE from '../../images/net-zero-navigator-image.png';
import { organisationLogo } from '../../data';
import { description } from '../../data';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('entity-navigator-index-layout');

// -------- Types -------->

export type EntityExplorerIndexLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const EntityExplorerIndexLayoutDefaultProps: Omit<EntityExplorerIndexLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(10)};
    padding-right: ${theme.UNIT(5)};
  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 90%;
    height: auto;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    
  `,
});

const StrategicPrioritiesLabelStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: #5CACC3;
    font-weight: 500;
  `,
});

const ItemsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    
  
    a {
      color: ${theme.COLOR('dark')};
      text-decoration: none;

    }

    @media (max-width: 1380px) {
      flex-wrap: wrap;
    }
   
  `,
});

const StrategicPrioritieButtonStyles = breakpoints({
  base: css`
    background-color: #F9F9F9;

    &:hover {
      border-color: ${theme.COLOR('primary')};
      color: ${theme.COLOR('primary')};
    } 

    .shp--image-bubble {
      height: 45px;
      width: 45px;
    }

    .shp--entity-value__name {
      font-size: 12px;
    }
  `,
  desktop: css`

    .shp--image-bubble {
      height: 80px;
      width: 80px;
    }

    .shp--entity-value__name {
      font-size: 14px;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const HostedByLogoStyles = breakpoints({
  base: css`
    display: flex;
    align-self: stretch;
    align-items: flex-end;
    justify-content: flex-end;
  `,
});

const LogoStyles = breakpoints({
  base: css`
    
  `,
});

const JumpLinksStyles = breakpoints({
  base: css`
    
  `,
});

const NavigatorGptPillStyles = breakpoints({
  base: css`
    
    border: 1px solid ${theme.COLOR('line-mid')};
    border-radius: 100px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(6)} ${theme.UNIT(2)} ${theme.UNIT(2)};
    background-color: ${theme.COLOR('light')};
    &:hover {
      border-color: ${theme.COLOR('primary')};
      ${theme.FILL('primary')};
      color: ${theme.COLOR('primary')};
    }

    .shp--icon {
      height: 60px;
      width: 60px;
    }
  `,
  desktop: css`
    .shp--icon {
      height: 80px;
      width: 80px;
    }
  `,
});

const NavigatorButtonsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Image: styled.img`${ImageStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Body: styled.div`${BodyStyles}`,
      StrategicPrioritiesLabel: styled(CellLabel)`${StrategicPrioritiesLabelStyles}`,
      Items: styled.div`${ItemsStyles}`,
        StrategicPriorityButton: styled(EntityValuePill)`${StrategicPrioritieButtonStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
  
    HostedByLogo: styled.div`${HostedByLogoStyles}`,
      Logo: styled(OrganisationPoweredBy)`${LogoStyles}`,

      JumpLinks: styled(SliceJumpLinks)`${JumpLinksStyles}`,

    NavigatorButtons: styled.div.attrs(cls.attr('NavigatorButtons'))`${NavigatorButtonsStyles}`,
      NavigatorGptPill: styled(IconTextValue)`${NavigatorGptPillStyles}`,

};

export const EntityExplorerIndexLayout: Shapeable.FC<EntityExplorerIndexLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { Link } = useLink();
  const pages = usePages();
  const t = useLang();

  const strategicPrioritiesPage = pages.find(page => page.name === 'Strategic Priorities');

  const filteredSlices = strategicPrioritiesPage
  ? strategicPrioritiesPage.slices
      .filter(slice => slice?.layout?.component === 'SliceLayoutSection')
      .map(slice => ({
        ...slice,
        image: {
          url: slice?.images[0]?.image?.url,
        }
        
      }))
  : [];

  return (
   <My.Container className={cls.name(className)}>
    <My.Image src={NET_ZERO_IMAGE} alt='net-zero-image' />
    <My.Intro entity={entity}/>

    <My.Body>
      <My.StrategicPrioritiesLabel>{t('Generate Personalised Results With:')}</My.StrategicPrioritiesLabel>
    
      <My.NavigatorButtons>
        <My.NavigatorGptPill href={entity.children[0]?.slug} iconSize={80} color='dark' iconComponent={AiLowerCaseIconGlyph}>{t('NavigatorGPT')}</My.NavigatorGptPill>
      </My.NavigatorButtons>
      <My.StrategicPrioritiesLabel>{t('Or Select A Strategic Priority:')}</My.StrategicPrioritiesLabel>
      <My.Items>
      {
        filteredSlices.map(slice => (
          <My.Items key={slice.id} id={slice.id}>
            <Link href={`${strategicPrioritiesPage.path}#${slice.slug}`}>
              <My.StrategicPriorityButton variant='large' showLabel={false} entity={slice} />
            </Link>
          </My.Items>
        ))
      }
      </My.Items>
      <My.Description entity={description as TContentNode} />

    </My.Body>

    <My.HostedByLogo>
      <My.Logo label='Hosted By:' entity={organisationLogo} />
    </My.HostedByLogo>

   </My.Container>
  )
};

EntityExplorerIndexLayout.defaultProps = EntityExplorerIndexLayoutDefaultProps;
EntityExplorerIndexLayout.cls = cls;